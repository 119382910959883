@import "Components/Styles/allStyles.css";

.loader {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
}

.loader ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

@keyframes preload {
  0% {
    background: var(--color-core-50);
    opacity: 1;
  }
  50% {
    background: #fff;
    opacity: 0.5;
  }
  100% {
    background: var(--color-core-50);
    opacity: 1;
  }
}

.loader .loading {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.loader .loading li {
  background: #fff;
  opacity: 0.5;
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 8px;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 50%;
}

.loader .loading li:first-child {
  margin-left: 0;
}

.loader .loading li:nth-child(2) {
  animation-delay: 0.15s;
}

.loader .loading li:nth-child(3) {
  animation-delay: 0.3s;
}

.loader.loader-complete {
  opacity: 0;
  visibility: hidden;
  transition-duration: 1s;
}
