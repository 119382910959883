.carousel .slider-wrapper {
  border-radius: 8px;
}

.carousel .carousel-status {
  background-color: rgba(0 0 0 / 30%);
  border-radius: 16px;
  text-shadow: none;
  padding: 4px;
  top: 6px;
  right: 6px;
  font-weight: 600;
}

.carousel .control-dots {
  margin: 4px 0;
}

.carousel .control-dots .dot {
  box-shadow: none;
  margin: 0 3px;
  width: 6px;
  height: 6px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 6px;
  background: rgba(0 0 0 / 5%);
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
  margin-left: 8px;
  margin-bottom: 1px;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
  margin-right: 8px;
  margin-bottom: 1px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0 0 0 / 30%);
}

@media screen and (max-width: 420px) {
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    display: none;
  }
}
