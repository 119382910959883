:root {
  --transition: all 0.3s ease-Out;
}

a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}

/* hide scrollbar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* non-selectable */
.non-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
